import * as React from "react"
// import PropTypes from "prop-types"

import "./button.css"

const Button = ( props ) => {
    return (
      <button className={props.className + " cta-btn btn-external btn-" + props.btnStyle}>
          <a href={props.btnLink} target={props.btnTarget}>
              {props.btnText}
          </a>
      </button>
    )
  }

export default Button
