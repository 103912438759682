import * as React from "react"

import TextSection from "./textSection"

import "./testimonialsSection.css"

const TestimonialsSection = ({ location, textSectionData, testimonials }) => {

    return (
        <div className={`${location}-testimonials testimonials`}>

            <div className="text-section-container">
                {textSectionData.title_visible &&
                  <TextSection className="description-medium" data={textSectionData} />
                }
            </div>

            <div className="testimonials-wrapper">
                {testimonials.map((testimonial) => (
                  <div className="testimonial-container">
                    <div className="testimonial-item">
                        <div className="testimonial-text">
                        <div className="layer-behind"></div>
                            {testimonial.frontmatter.text}
                        </div>
                    </div>
                  </div>
                ))}
            </div>

        </div>
    )
  }

export default TestimonialsSection