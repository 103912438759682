import React, { useState, useMemo } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Container from "./container"
import AnimationWrapper from "./animationWrapper"
import CustomAniLink from "./customAniLink"
import LightboxImage from "./lightboxImage"
import TextSection from "./textSection"
import InnerSection from "./innerSection"
import Button from "./button"
import ButtonExternal from "./buttonExternal"

import "./chooseTilesSection.css"

const ChooseTilesSection = ({ className, data }) => {

  const [tilesData, setTilesData] = useState([]);

  const downloads = useStaticQuery(
    graphql`
      query {
        files: allMdx(
          filter: {
            internal: {contentFilePath: {regex: "/(downloads)/"}},
            frontmatter: {category: {eq: "download"}}
            }
        ) {
          nodes {
            frontmatter {
              name
              slug
              file {
                publicURL
              }
            }
            id
          }
        }
      }
    `
  )

  useMemo(() => {
    const tilesData = [];
    let publicUrl;
    data.choose_tiles.forEach(function(tile){

      if (tile.file){
        // Get files publicURLs
        downloads.files.nodes.forEach(function(download){
          if(download.frontmatter.slug === tile.file){
            publicUrl = download.frontmatter.file.publicURL;
          }
        });
      }
      // Save new values to tilesData
      tilesData.push({
        name: tile.name,
        image: tile.image,
        link: tile.link,
        type: tile.type,
        status: tile.status,
        description: tile.description,
        button_text: tile.button_text,
        button_style: tile.button_style,
        file: publicUrl
      });
    });
    // Initialize voivodships object
    setTilesData(tilesData);
  }, [data, downloads]);

  return (
    <div className={`choose-tiles-wrapper ${className}`}>

      {data.title_visible &&
        <Container>
          <AnimationWrapper>
            <TextSection data={data} />
          </AnimationWrapper>
        </Container>
      }

      <InnerSection className={`choose-tiles ${data.align}`}>
        {tilesData.map((tile) => (
          <div className="tile-wrapper">
            <div className="image-container">
              {tile.type === 'link' &&
                <CustomAniLink Link={tile.link}>
                  {tile.status === 'sold' &&
                    <div class="sold-overlay">Sprzedane</div>
                  }
                  <GatsbyImage
                    image={tile.image.childImageSharp.gatsbyImageData}
                    alt={tile.name}
                  />
                </CustomAniLink>
              }
              {tile.type === 'lightbox' &&
                <LightboxImage image={tile.image} />
              }
            </div>
            {tile.description &&
              <div className="description-wrapper">
                <div className="section-description" dangerouslySetInnerHTML={{ __html: tile.description }} />
                {tile.button_text &&
                  <>
                    {tile.file &&
                      <ButtonExternal btnStyle={tile.button_style} btnLink={tile.file} btnTarget="_blank" btnText={tile.button_text} />
                    }
                    {!tile.file &&
                      <Button btnStyle={tile.button_style} btnLink={tile.link} btnText={tile.button_text} />
                    }
                  </>
                }
              </div>
            }
          </div>
        ))}
      </InnerSection>

    </div>
  )
}

export default ChooseTilesSection