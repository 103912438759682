import * as React from "react"

import Container from "./container"
import AnimationWrapper from "./animationWrapper"
import TextSection from "./textSection"
import LightboxImage from "./lightboxImage"

const LightboxImageGallery = ( { data } ) => {

  return (

    <div className={`lightboxImageGallery ${data.type} `}>

      {data.title_visible &&
        <Container>
          <AnimationWrapper>
            <TextSection data={data} />
          </AnimationWrapper>
        </Container>
      }

      {data.photos.map((photo) => (
        <AnimationWrapper>
          <LightboxImage image={photo.image} />
        </AnimationWrapper>
      ))}

    </div>
  )
}

export default LightboxImageGallery